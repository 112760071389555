@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.root {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}

.title {
  @include font-style-subtitle($font-weight-bold);
  margin: 0;
}
